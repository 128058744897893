import {
  STORE_CONTRACT,
  RESET_CONTRACT,
  SIMULATE_SUCCESSFUL_CONTRACT,
  SIMULATE_UNSUCCESSFUL_CONTRACT,
} from '../actions/contract-action';
import {
  ContractReducer,
  ContractResponse,
  ContractObject,
  ContractObjectContent,
  VerifyResponse,
  CheckMobileResponse,
  CheckEmailResponse,
} from '../types/contract.d';

import {
  SAVE_CONTRACT,
  VALIDATE_VERIFY_CODE,
  CHECK_MOBILE,
  CHECK_EMAIL,
  CONFIRM_SIGNED_CONTRACT,
  RESIGN_CONTRACT,
  UPDATE_CONTRACT_ADDRESS,
} from '../api/actionSets';

const defaultContractContent: ContractObjectContent = {
  token: null,
  contractPerson: {
    birthNumber: null,
    name: null,
    lastName: null,
    birthName: null,
    titleBefore: null,
    titleAfter: null,
    nationality: null,
    placeOfBirth: null,
    maritalStatus: null,
  },
  permanentAddress: null,
  postalAddress: null,
  mobil: null,
  email: null,
  iban: null,
  zp: null,
  retirement: null,
  severelyDisabled: null,
  contractData: {
    superiors: null,
    superiorID: 0,
    subSuperior: null,
    subSuperiorID: 0,
    masterAgreement: null,
    internalNumber: null,
  },
  identityFrontSideDoc: {
    token: null,
    applicationName: null,
    name: null,
    mime: null,
    content: null,
    documentBioSignKey: null,
  },
  identityBackSideDoc: {
    token: null,
    applicationName: null,
    name: null,
    mime: null,
    content: null,
    documentBioSignKey: null,
  },
  retirementDoc: {
    token: null,
    applicationName: null,
    name: null,
    mime: null,
    content: null,
    documentBioSignKey: null,
  },
  severelyDisabledCardDoc: {
    token: null,
    applicationName: null,
    name: null,
    mime: null,
    content: null,
    documentBioSignKey: null,
  },
  dataMiningOCR: false,
};

const defaultContract: ContractObject = {
  contractInput: defaultContractContent,
};

const defaultContractResponse: ContractResponse = {
  contractToken: null,
  documentSignatusId: null,
  pdfContent: null,
  xfdf: null,
};

const defaultVerifyResponse: VerifyResponse = {
  result: null,
  levelRemuneration: null,
  masterAgreement: null,
  contract: null,
};

const defaultCheckMobileResponse: CheckMobileResponse = {
  unique: null,
};

const defaultCheckEmailResponse: CheckEmailResponse = {
  unique: null,
};

const defaultState: ContractReducer = {
  isLoading: true,
  wizardInProgress: true,
  isSaved: false,
  isFinished: false,
  error: null,
  contract: defaultContract,
  content: defaultContractResponse,
  verify: {
    content: defaultVerifyResponse,
    isLoading: false,
    isValid: null,
    error: null,
  },
  checkMobile: {
    content: {
      unique: null,
    },
    isLoading: false,
    isValid: null,
    error: null,
  },
  checkEmail: {
    content: {
      unique: null,
    },
    isLoading: false,
    isValid: null,
    error: null,
  },
  additional: {
    isError: false,
    isLoading: false,
    email: null,
  },
  updateContractData: {
    isLoading: false,
    isLoaded: false,
    is_error: false,
    error: null,
  },
};

export default (state: ContractReducer = defaultState, action) => {
  switch (action.type) {
    case SAVE_CONTRACT.LOADING:
      return {
        ...state,
        isLoading: true,
        isSaved: true,
        isFinished: false,
        wizardInProgress: false,
        error: null,
        content: defaultContractResponse,
      };
    case SIMULATE_SUCCESSFUL_CONTRACT:
    case SAVE_CONTRACT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSaved: true,
        isFinished: true,
        wizardInProgress: false,
        error: null,
        content: action.response,
      };
    case SIMULATE_UNSUCCESSFUL_CONTRACT:
    case SAVE_CONTRACT.ERROR:
    case RESIGN_CONTRACT.ERROR:
      return {
        ...state,
        isLoading: false,
        isSaved: true,
        isFinished: false,
        wizardInProgress: false,
        error: true,
        content: defaultContractResponse,
      };
    case RESIGN_CONTRACT.LOADING:
      return {
        ...state,
        isLoading: true,
        isSaved: true,
        isFinished: false,
        wizardInProgress: false,
        content: {
          ...state.content,
        },
      };
    case RESIGN_CONTRACT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSaved: true,
        isFinished: true,
        wizardInProgress: false,
        content: {
          ...state.content,
          ...action.response,
        },
      };
    case VALIDATE_VERIFY_CODE.LOADING:
      return {
        ...state,
        verify: {
          ...state.verify,
          content: defaultVerifyResponse,
          isLoading: true,
          isValid: false,
          error: null,
        },
      };
    case VALIDATE_VERIFY_CODE.SUCCESS:
      return {
        ...state,
        verify: {
          ...state.verify,
          content: action.response,
          isLoading: false,
          isValid: action.response.result,
          error: action.response.errorMessage,
        },
      };
    case VALIDATE_VERIFY_CODE.ERROR:
      return {
        ...state,
        verify: {
          ...state.verify,
          content: defaultVerifyResponse,
          isLoading: false,
          isValid: false,
          error: true,
        },
      };
    case CHECK_MOBILE.LOADING:
      return {
        ...state,
        checkMobile: {
          ...state.checkMobile,
          content: defaultCheckMobileResponse,
          isLoading: true,
          isValid: false,
          error: null,
        },
      };
    case CHECK_MOBILE.SUCCESS:
      return {
        ...state,
        checkMobile: {
          ...state.checkMobile,
          content: action.response,
          isLoading: false,
          isValid: action.response.result,
          error: action.response.errorMessage,
        },
      };
    case CHECK_MOBILE.ERROR:
      return {
        ...state,
        checkMobile: {
          ...state.checkMobile,
          content: defaultCheckMobileResponse,
          isLoading: false,
          isValid: false,
          error: true,
        },
      };
    case CHECK_EMAIL.LOADING:
      return {
        ...state,
        checkEmail: {
          ...state.checkMobile,
          content: defaultCheckEmailResponse,
          isLoading: true,
          isValid: false,
          error: null,
        },
      };
    case CHECK_EMAIL.SUCCESS:
      return {
        ...state,
        checkEmail: {
          ...state.checkMobile,
          content: action.response,
          isLoading: false,
          isValid: action.response.result,
          error: action.response.errorMessage,
        },
      };
    case CHECK_EMAIL.ERROR:
      return {
        ...state,
        checkEmail: {
          ...state.checkMobile,
          content: defaultCheckEmailResponse,
          isLoading: false,
          isValid: false,
          error: true,
        },
      };
    case CONFIRM_SIGNED_CONTRACT.LOADING:
      return {
        ...state,
        additional: {
          isError: false,
          isLoading: true,
          email: null,
        },
      };
    case CONFIRM_SIGNED_CONTRACT.SUCCESS:
      return {
        ...state,
        additional: {
          isError: false,
          isLoading: false,
          email: action.response.confirmAdditionalData?.email,
        },
      };
    case CONFIRM_SIGNED_CONTRACT.ERROR:
      return {
        ...state,
        additional: {
          isLoading: false,
          isError: true,
          email: null,
        },
      };
    case STORE_CONTRACT:
      return {
        ...state,
        isLoading: false,
        wizardInProgress: false,
        isSaved: true,
        isFinished: false,
        error: null,
        content: action.data,
      };
    case RESET_CONTRACT:
      return {
        ...state,
        isLoading: false,
        error: null,
        isSaved: false,
        isFinished: false,
        wizardInProgress: true,
        content: defaultContract,
        // signType: null,
      };

    case UPDATE_CONTRACT_ADDRESS.LOADING:
      return {
        ...state,
        updateContractData: {
          isLoading: true,
          isLoaded: false,
          is_error: false,
          error: null,
        },
      };
    case UPDATE_CONTRACT_ADDRESS.ERROR:
      return {
        ...state,
        updateContractData: {
          isLoading: false,
          isLoaded: true,
          is_error: true,
          error: action,
        },
      };
    case UPDATE_CONTRACT_ADDRESS.SUCCESS:
      return {
        ...state,
        updateContractData: {
          isLoading: false,
          isLoaded: true,
          is_error: false,
          error: null,
        },
      };

    default:
      return state;
  }
};
